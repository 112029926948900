import React, { Suspense } from 'react'
import { Loader } from '../../lib/components/loader'
import styled from 'styled-components'
import { device } from '../../lib/utils/device'
import { Offers } from '../components/offers'
import { useLocation } from 'react-router'
import { useOffers } from '../hooks/offers.hook'

const PlacesList = React.lazy(() => import('../components/placesList'))


export function Home() {
  const location = useLocation()
  const {offers: {data: {data: {data} = []} = []} = []} = useOffers(location.search)
  return (
    <>
      <Container>
        <ComponentContainer>
          <Suspense fallback={<Loader/>}>
            <PlacesList/>
          </Suspense>
        </ComponentContainer>
      </Container>
      <TableContainer>
        <Offers type="ONE_DAY" data={data}/>
        <Offers type="THREE_DAYS" data={data}/>
        <Offers type="WEEK_END" data={data}/>
        <Offers type="ONE_WEEK" data={data}/>
        <Offers type="ONE_MONTH" data={data}/>
      </TableContainer>
    </>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TableContainer = styled.div`
  max-width: 95%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-width: 100px;
`

const ComponentContainer = styled.div`
	width: 40%;
	display: flex;
	min-width: 400px;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media ${device.custom} {
		width: 100%;
		min-width: auto;
	}
`
