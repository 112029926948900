import axios from 'axios'

const logInRequest = (params) => {
  return axios.post(`${process.env.NX_CUSTOM_VAR_API_URL}/api/auth/login`,
    {
      email: params.email,
      password: params.password
    })
}

const getPlace = () => {
  return axios.get(`${process.env.NX_CUSTOM_VAR_API_URL}/api/scrapper/all`)
}

const getOffers = (key, params) => {
  if (!params) return
  return axios.get(`${process.env.NX_CUSTOM_VAR_API_URL}/api/offers/get${params}`)
}

export {
  logInRequest,
  getPlace,
  getOffers
}
