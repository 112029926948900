import React from 'react'
import styled from 'styled-components'

function Space({large}) {
  return (
    <HeightSpace large={large}/>
  )
}

const HeightSpace = styled.div`
	height: ${props => props.large ? '40px' : '20px'};
`

export {
  Space
}
