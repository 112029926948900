import axios from 'axios'
import { useStorage } from '../../lib/utils/hooks/useStorage.hook'

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response && (error.response.status === 401 || error.message == 'Network Error')) {
    const {clear} = useStorage()
    clear()
    window.location.replace('/')
  }
  return Promise.reject(error)
})

function useAuth() {
  const token = localStorage.getItem('token')
  let isLoggedIn = false

  if (token) {
    axios.defaults.headers.common['x-access-token'] = token
    isLoggedIn = true
  }
  return {
    token: token,
    isLoggedIn
  }
}

export {
  useAuth
}
