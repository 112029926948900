import {useQuery} from 'react-query'
import {getOffers} from '../../api'

function useOffers(params) {
  return {
    offers: useQuery([`offerList${params}`, params], getOffers),
  }
}

export {
  useOffers
}
