import React from 'react'
import { Route } from 'react-router-dom'
import { SignIn } from '../user/pages/sign-in'

function UnauthenticatedApp() {
  return (
    <Route exact path="/">
      <SignIn/>
    </Route>
  )
}

export {
  UnauthenticatedApp
}


