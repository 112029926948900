import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0f04fe'
    },
    secondary: {
      main: '#000000'
    }
  }
})

export {
  theme
}
