import axios from 'axios'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useStorage } from '../../lib/utils/hooks/useStorage.hook'

const defaultUser = {
  email: '',
  password: '',
  first_name: '',
  last_name: ''
}

function useUser() {
  const [user, setUser] = useState(defaultUser)
  const [error, setError] = useState(null)
  const history = useHistory()
  const {set} = useStorage()
  const setInfoUser = (data) => setUser({...user, ...data})
  const handleLogin = {
    onMutate: () => {
      setError(null)
    },
    onError: (error) => {
      setError(error.response.data.message)
    },
    onSuccess: (data) => {
      set('token', data.data.token)
      axios.defaults.headers.common['x-access-token'] = data.data.token
      return history.replace('/')
    }
  }

  return {
    user,
    setInfoUser,
    error,
    handleLogin
  }
}

export {
  useUser
}
