import { Typography } from '@material-ui/core'
import React from 'react'
import { format } from 'date-fns'

const DisplayDateOfScrapping = ({date}) => {
  return (
    <Typography style={{padding: '0 0 20px 20px', borderBottom: '1px solid lightgrey'}}
                variant="subtitle2"
                component="p"
                color="textSecondary"
                paragraph>
      From {format(new Date(date[0]), 'EEEE dd MMM yyyy H:mm')} to {format(new Date(date[1]), 'EEEE dd MMM yyyy H:mm')}
    </Typography>
  )
}

const DisplayDate = ({date}) => {
  return (
    <Typography style={{marginLeft: '20px'}}
                variant="subtitle2"
                component="p"
                color="textSecondary"
                paragraph>Scrapped on {format(new Date(date), 'EEEE dd MMM yyyy H:mm')}
    </Typography>
  )
}

export {
  DisplayDate,
  DisplayDateOfScrapping
}
