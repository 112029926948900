import React from 'react'
import styled from 'styled-components'

function LogoJQ() {
  return (
    <ImgContainer>
      <img alt="Logo JQ-tech" src='../../../assets/logoJQ.png'/>
    </ImgContainer>
  )
}

const ImgContainer = styled.div`
	display: flex;
	justify-content: center;
	img {
		width: 100%;
		max-width: 300px;
		height: 100%;
		display: block;
	}
`

export {
  LogoJQ
}
