import React from 'react'
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { Space } from '../../../lib/components/space'
import { useStorage } from '../../../lib/utils/hooks/useStorage.hook'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

function TitleHeaderDrawer() {
  return (
    <Typography variant="h6" noWrap uppercase="true">
      Car Rental Prices Overview
    </Typography>
  )
}

function PersonalInfosInMenu() {
  return (
    <>
      <Divider/>
      <Space/>
      <Typography variant="subtitle1" gutterBottom noWrap style={{marginLeft: '16px'}}>
        Hello Gayus 🚀
      </Typography>
      <Space/>
    </>
  )
}

function TopNavigationMenu() {
  const nav = []
  return (
    <>
      {nav.map((item, index) => (
        <Link to={item.link} key={index} style={{textDecoration: 'none', color: 'rgba(0,0,0,0.87)'}}>
          <ListItem button selected={window.location.pathname === item.link}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text}/>
          </ListItem>
        </Link>
      ))}
    </>
  )
}

function BottomNavigationMenu() {
  const {clear} = useStorage()
  const nav = [{
    text: 'Disconnect',
    icon: <ExitToAppIcon/>,
    link: '/',
    action: () => {
      clear()
    }
  }]
  return (
    <>
      {nav.map((item, index) => (
        <Link onClick={() => item.action && item.action()} to={item.link} key={index}
              style={{textDecoration: 'none', color: 'rgba(0,0,0,0.87)'}}>
          <ListItem button selected={window.location.pathname === item.link}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text}/>
          </ListItem>
        </Link>
        )
      )}
    </>
  )
}

export {
  TopNavigationMenu,
  BottomNavigationMenu,
  TitleHeaderDrawer,
  PersonalInfosInMenu
}
