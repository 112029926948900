import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import { Space } from './space'

function InputLogForm({type, label, value, onChange, name}) {
  return (
    <>
      <FormControl fullWidth={true}
                   required={true}
                   size="medium"
                   style={{width: '300px'}}
                   variant="outlined"
                   margin="dense">
        <InputLabel focused={true}>{label}</InputLabel>
        <Input
          type={type}
          value={value}
          onChange={onChange}
          name={name}
        />
      </FormControl>
      <Space/>
    </>
  )
}

function ButtonLogForm({label, form, disabled}) {
  return (
    <>
      <FormControl
        margin="dense">
        <ButtonSixt
          type="submit"
          variant="contained"
          form={form}
          color="secondary"
          disabled={disabled}
        >
          {label}
        </ButtonSixt>
      </FormControl>
      <Space/>
    </>
  )
}

const ButtonSixt = withStyles((theme) => ({
  root: {
    fontWeight: 'bold',
    letterSpacing: '1.5px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  }
}))(Button)


export {
  InputLogForm,
  ButtonLogForm
}
