import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { SignInForm } from '../../components/signInForm'
import { LogoJQ } from '../../../layout/components/logo/logoJQ'
import { device } from '../../../lib/utils/device'

function SignIn() {
  return (
    <Main>
      <TitleContainer>
        <LogoJQ/>
        <Typography align="center" variant="h5" component="h1" color="textSecondary">Sign in</Typography>
      </TitleContainer>
      <SignInForm/>
    </Main>
  )
}

const Main = styled.div`
	display: flex;
	width: 100%;
	height: 100vh;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	@media ${device.custom} {
		height: auto;
	}
`

const TitleContainer = styled.div`
	width: 100%;
	height: 20%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	@media ${device.custom} {
		margin-bottom: 50px;
		margin-top: 50px;
	}
`

export {
  SignIn
}
