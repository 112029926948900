import React from 'react'

function useStorage() {
  return {
    set(key, data) {
      localStorage.setItem(key, data)
    },
    clear() {
      localStorage.clear()
    }
  }
}

export {
  useStorage
}
