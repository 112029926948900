import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'
import React from 'react'

export function Loader() {
  return (
    <LoadingContainer>
      <CircularProgress/>
    </LoadingContainer>
  )
}

const LoadingContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`
