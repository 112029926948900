import React from 'react'
import { Route } from 'react-router-dom'
import DrawerLayout from '../lib/components/drawerLayout'
import { Home } from '../home/pages/home'

function AuthenticatedApp() {
  return (
    <Route exact path="/">
      <DrawerLayout>
        <Home/>
      </DrawerLayout>
    </Route>
  )
}

export {
  AuthenticatedApp
}

