import React from 'react'
import {Reset} from 'styled-reset'
import styled from 'styled-components'
import {ThemeProvider} from '@material-ui/styles'
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import {theme} from '../config/theme'
import {AuthenticatedApp} from '../auth/authenticatedApp'
import {UnauthenticatedApp} from '../auth/unauthenticatedApp'
import {useAuth} from '../auth/hooks/auth.hook'
import {QueryCache, ReactQueryCacheProvider} from 'react-query'

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export const App = () => {
  function DisplayApp() {
    const {isLoggedIn} = useAuth()
    return isLoggedIn ? <AuthenticatedApp/> : <UnauthenticatedApp/>
  }

  return (
    <>
      <Reset/>
      <ThemeProvider theme={theme}>
        <ReactQueryCacheProvider queryCache={queryCache}>
          <GlobalContainer>
            <Router>
              <Switch>
                <DisplayApp/>
              </Switch>
            </Router>
          </GlobalContainer>
        </ReactQueryCacheProvider>
      </ThemeProvider>
    </>
  )
}

const GlobalContainer = styled.div`
    width: 100%;
    height: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
`

export default App
