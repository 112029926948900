import React from 'react'
import styled from 'styled-components'
import { device } from '../../lib/utils/device'
import { useMutation } from 'react-query'
import { useUser } from '../hooks/connect.hook'
import Typography from '@material-ui/core/Typography'
import { logInRequest } from '../../api'
import { ButtonLogForm, InputLogForm } from '../../lib/components/connectFormCustom'

function SignInForm() {
  const {user: {email, password}, setInfoUser, handleLogin, error} = useUser()
  const handleFormInput = ({target: {name, value}}) => {
    setInfoUser({[name]: value})
  }
  const [mutate] = useMutation(logInRequest)

  const submitForm = async (e) => {
    e.preventDefault()
    await mutate({email, password}, handleLogin)
  }

  return (

    <FormContainer>
      <Form method="post"
            id="sign_in_form"
            onSubmit={submitForm}
      >
        <InputLogForm type="text"
                      label="Email"
                      value={email}
                      onChange={handleFormInput}
                      name="email"
        />
        <InputLogForm type="password"
                      label="Password"
                      value={password}
                      onChange={handleFormInput}
                      name="password"
        />
        <ButtonLogForm label="Submit"
                       form="sign_in_form"
                       disabled={(email && password) === ''}
        />
      </Form>
      {error && <Typography align="center" variant="body2" component="h6" color="error">{error}</Typography>}
    </FormContainer>
  )
}

const FormContainer = styled.div`
	width: 100%;
	height: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	@media ${device.custom} {
		margin-bottom: 50px;
	}
`

const Form = styled.form`
	width: 300px;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
`

export {
  SignInForm
}
