import React from 'react'
import TableContainer from '@material-ui/core/TableContainer'
import { Paper, TableBody } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/styles'
import { from } from 'rxjs'
import { distinct, groupBy, map, mergeMap, toArray } from 'rxjs/operators'
import { Loader } from '../../lib/components/loader'
import { Space } from '../../lib/components/space'
import { DisplayDate, DisplayDateOfScrapping } from '../../lib/components/displayDate'

const LoadingState = React.memo(({classes}) => (<div className={classes.container}><Loader/></div>))

function Offers({type = 'ONE_DAY', data}) {
  const classes = useStyles()
  if (!data) return <LoadingState classes={classes}/>

  const classification = ['Économique', 'Compacte', 'Intermédiaire']
  const getOffers = data.find(t => type === t.type)
  const rangeDate = getOffers.taskId.payload.rangeDates.find(e => type === e.type)
  const offerIndex = data.findIndex(t => type === t.type)
  const offers = getOffers.offers.filter(offer => classification.includes(offer.vehicle.classification.name))
  const source = from(offers)
  const tabRows = []
  const brands = []
  const indBrands = []

  source.pipe(
    distinct((offer) => offer.vendor.name)
  ).subscribe(brand => {
    brands.push(brand)
    indBrands.push(brand.vendor.name)
  })

  source.pipe(
    groupBy(offer => offer.vehicle.classification.name),
    mergeMap(group => group.pipe(
      toArray()
    )),
    map(e => {
      return formatTableCellWithEmptyValue(e)

    })
  ).subscribe(val => tabRows.push(val))

  function findCategory(array) {
    return array.find(elem => {
      return !!elem
    })
  }

  function formatTableCellWithEmptyValue(e) {
    const d = new Array(indBrands.length).fill(undefined)
    for (let i = -1; i < indBrands.length; i++) {
      if (e[i]) {
        const ind = indBrands.findIndex(n => n === e[i].vendor.name)
        d[ind] = e[i]
      }
    }
    return d
  }

  function getBestOfferInCategory(p, n) {
    if (n) return Number(p.price) < Number(n.price) ? p : n
    return p
  }

  function getColorBestOffer(row, lowerPrice) {
    return row.price === lowerPrice.price ? 'bestOffer' : ''
  }

  const ListTableCell = React.memo(({items, lowerPrice}) => {
    return items.map((row, index) => {
      if (row) {
        return (
          <TableCell key={generateKey(`${row.price}-${index}`)}
                     align="left"
                     colSpan={1}>
            <span className={classes[getColorBestOffer(row, lowerPrice)]}>{row.price.toFixed(2)} €</span>
          </TableCell>
        )
      } else {
        return <TableCell key={generateKey(index)} align="left" colSpan={1}/>
      }
    })
  })

  const ListTableRow = React.memo(({items}) => {
    return items.map((rows) => {
      const lowerPrice = rows.reduce(getBestOfferInCategory, 0)
      const category = findCategory(rows)

      return (
        <TableRow key={generateKey(category.vehicle.classification.name)}>
          <TableCell align="left" colSpan={1}>{category.vehicle.classification.name}</TableCell>
          <TableCell align="left" colSpan={1}>Expedia</TableCell>
          <ListTableCell items={rows} lowerPrice={lowerPrice}/>
        </TableRow>
      )
    })
  })

  const ListTableHead = React.memo(({items}) => {
    return items.map(brand => (
      <TableCell align="left" colSpan={1} key={generateKey(brand.vendor.name)}>
        <img src={brand.vendor.imageUrl} width={80} alt={brand.vendor.name}/>
      </TableCell>
    ))
  })

  const generateKey = (pre) => {
    return `${pre}_${new Date().getTime()}`
  }

  const mappingType = {
    'ONE_DAY': 'J+2 / 9h - J+3 / 9h',
    'THREE_DAYS': 'J+2 / 9h - J+5 / 9h',
    'ONE_WEEK': 'J+2 / 9h - J+9 / 9h',
    'ONE_MONTH': 'J+2 + 28 jours',
    'WEEK_END': 'Next week-end'
  }

  console.log(rangeDate.date)
  return (
    <>
      <TableContainer component={Paper} className={classes.container}>
        <Space/>
        <DisplayDateOfScrapping date={rangeDate.date} index={offerIndex}/>
        <Table className={classes.table} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell align="left" colSpan={1}>
                {mappingType[getOffers.type]}
              </TableCell>
              <TableCell align="left" colSpan={1}>
                Source
              </TableCell>
              <ListTableHead items={brands}/>
            </TableRow>
          </TableHead>
          <TableBody>
            <ListTableRow items={tabRows}/>
          </TableBody>
        </Table>
        <Space/>
        <DisplayDate date={getOffers.created_at}/>
      </TableContainer>
    </>
  )
}

const useStyles = makeStyles({
  table: {
    minWidth: 100,
    height: '100%',
    minHeight: 200
  },
  container: {
    margin: '40px 0',
    overflow: 'auto',
    minHeight: 200,
    minWidth: 100
  },
  bestOffer: {
    background: '#00E676',
    fontWeight: 'bold',
    padding: 8,
    borderRadius: 4
  }
})

export {
  Offers
}
